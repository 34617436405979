import firebase from 'firebase'

const config = {
  apiKey: "AIzaSyDZjAMqmqMFwnx0yDVkqNAD3sI4S_c91Ps",
  authDomain: "amanata-e46ee.firebaseapp.com",
  projectId: "amanata-e46ee",
  storageBucket: "amanata-e46ee.appspot.com",
  messagingSenderId: "272603181136",
  appId: "1:272603181136:web:1051b3e4422b451fe16155"
}

if (!firebase.apps.length) { 
  firebase.initializeApp(config)
}

export const db = firebase.firestore()

export default (ctx, inject) => {
  inject('firebase', firebase)
}